<template>

  <div>
    <SuggestTeacher
      v-if="suggestTeacherData.active"
      :courseId="suggestTeacherData.courseId"
      :isModalSActive.sync="suggestTeacherData.active"
      :teachers="teachers"
      @refetch-data="refetchData"
    />
    <SuggestClassroom
      v-if="suggestClassroomData.active"
      :courseId="suggestClassroomData.courseId"
      :isModalSActive.sync="suggestClassroomData.active"
      @refetch-data="refetchData"
    />

    <!-- side filter-->
    <course-list-side-filter
      v-if="isFilterActive"
      :TofDArray="TofDArray"
      :classroom_id.sync="classroom_id"
      :classrooms="classrooms"
      :date_end_from.sync="date_end_from"
      :date_end_to.sync="date_end_to"
      :date_start_from.sync="date_start_from"
      :date_start_to.sync="date_start_to"
      :fStatus.sync="fStatus"
      :group.sync="group"
      :isFilterActive.sync="isFilterActive"
      :mastercourse_id.sync="mastercourse_id"
      :masters="masters.rows"
      :number.sync="number"
      :statusArray="statusArray"
      :teacher_id.sync="teacher_id"
      :teachers="teachers"
      :time_of_day.sync="time_of_day"
    />
    <!-- :cities="cities" -->

    <!-- add course modal -->
    <courses-list-add
      v-if="isAddActive"
      :TofDArray="TofDArray"
      :classrooms="classrooms"
      :course-action="'addFromModal'"
      :fromModal="true"
      :isAddActive.sync="isAddActive"
      :masters="masters.rows"
      :statusArray="statusArray"
      :teachers="teachers"
      @refetch-data="refetchData"
    />
    <!-- :cities="cities" -->
    <courses-list-group-add
      :course="course"
      :courses="coursesList"
      :is-add-new-student-sidebar-active.sync="isAddNewStudentSidebarActive"
      :methods="methodOptions"
      :paidOptions="paidOptions"
      :payer-options="payerOptions"
      @refetch-data="refetchData"
    />

    <!-- add similar course modal -->
    <courses-list-add
      v-if="isAddsimilarActive"
      :TofDArray="TofDArray"
      :classrooms="classrooms"
      :course-action="courseAction"
      :currentCourseData="currentCourseData"
      :isAddActive.sync="isAddsimilarActive"
      :masters="masters.rows"
      :statusArray="statusArray"
      :teachers="teachers"
      @refetch-data="refetchData"
      @suggest-classrooms="$emit('suggest-classrooms')"
      @suggest-teacher="$emit('suggest-teacher')"
    />
    <!-- :cities="cities" -->

    <!-- old edit course modal -->
    <!--    <modal-course-->
    <!--      :isModalActive.sync="isModalActive"-->
    <!--      :data="singleData"-->
    <!--      :classrooms="classrooms"-->
    <!--      :teachers="teachers"-->
    <!--      :masters="masters.rows"-->
    <!--      :cities="cities"-->
    <!--      :msgs="msgs"-->
    <!--      :statusArray="statusArray"-->
    <!--      :UsersToAdd="UsersToAdd"-->
    <!--      :UsersAlreadyAdded="UsersAlreadyAdded"-->
    <!--      :currentUser="currentUser"-->
    <!--      :loading="spinner"-->
    <!--      @refetch-data="refetchData"-->
    <!--      @initilize="ModalData($event)"-->
    <!--      @loading="loadSpinner"-->
    <!--      v-if="isModalActive"-->
    <!--    />-->

    <!-- new big modal -->
    <!--    <courses-list-big-modal-->
    <!--      :isBigModalActive.sync="isBigModalActive"-->
    <!--      :classrooms="classrooms"-->
    <!--      :teachers="teachers"-->
    <!--      :masters="masters.rows"-->
    <!--      :cities="cities"-->
    <!--      :TofDArray="TofDArray"-->
    <!--      :currentCourseData="currentCourseData"-->
    <!--      :msgs="msgs"-->
    <!--      :statusArray="statusArray"-->
    <!--      :UsersToAdd="UsersToAdd"-->
    <!--      :UsersAlreadyAdded="UsersAlreadyAdded"-->
    <!--      :currentUser="currentUser"-->
    <!--      :loading="spinner"-->
    <!--      @refetch-data="refetchData"-->
    <!--      @initilize="ModalData($event)"-->
    <!--      @loading="loadSpinner"-->
    <!--      v-if="isBigModalActive"-->
    <!--    />-->

    <!-- Table Container Card -->
    <b-card
      class="mb-0"
      no-body
    >

      <div :class="details ? 'mx-2' : 'm-2'">

        <!-- Table Top -->
        <b-row>

          <!-- Status Filter -->
          <b-col
            v-if="!isOverView"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="6"
          >
            <b-button
              v-if="!allRowsSelected && !details && selectedRows.length === 0"
              class="btn-sm"
              @click="selectAllRows"
            >
              Select all
            </b-button>
            <b-button
              v-else-if="!details && selectedRows.length > 0"
              class="btn-sm"
              @click="clearSelected"
            >
              Clear selected
            </b-button>
            <!--            <v-select-->
            <!--              v-model="filterDefault"-->
            <!--              label="label"-->
            <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--              :options="coursesStatusOptions"-->
            <!--              :clearable="false"-->
            <!--              class="courses-filter d-inline-block mx-50"-->
            <!--            />-->
          </b-col>

          <!-- Search && multi actions -->
          <b-col
            v-if="!isOverView"
            cols="12"
            md="6"
          >
            <!-- one modal for all multiple rows actions -->
            <b-modal
              :id="'modalquestionGroup'"
              :title="capitalizeFirstLetter(modalAction) + ' courses'"
              cancel-title="No"
              centered
              ok-title="Yes"
              @ok="multipleRowActions(modalAction)"
            >
              <b-card-text>
                <h5>Do you want to {{ modalAction }} <strong> these courses ?</strong></h5>
              </b-card-text>
            </b-modal>

            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="selectedRows.length !== 0 && !selectedRows.some(el => el.status !== 'deleted')"
                v-b-modal.modalquestionGroup
                v-b-tooltip.hover.top="'Delete'"
                class="d-inline-block btn-sm mr-1"
                variant="primary"
                @click="modalAction = 'delete'"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              <!-- <b-button
                v-if="selectedRows.length !== 0"
                v-b-modal.modalquestionGroup
                v-b-tooltip.hover.top="'Archive'"
                variant="primary"
                class="d-inline-block btn-sm mr-1"
                @click="modalAction = 'archive'"
              >
                <feather-icon icon="ArchiveIcon" />
              </b-button> -->

              <b-button
                v-if="!details && selectedRows.length !== 0 && !selectedRows.some(el => el.status === 'hidden')"
                v-b-modal.modalquestionGroup
                v-b-tooltip.hover.top="'Hide'"
                class="d-inline-block btn-sm mr-1"
                variant="primary"
                @click="modalAction = 'hide'"
              >
                <feather-icon icon="EyeOffIcon" />
              </b-button>

              <b-button
                v-if="!details && selectedRows.length !== 0 && !selectedRows.some(el => el.status !== 'hidden')"
                v-b-modal.modalquestionGroup
                v-b-tooltip.hover.top="'Make visible'"
                class="d-inline-block btn-sm mr-1"
                variant="primary"
                @click="modalAction = 'show'"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <!--              <b-form-input-->
              <!--                v-model="searchQuery"-->
              <!--                class="d-inline-block mr-1"-->
              <!--                placeholder="Search..."-->
              <!--                style="width: 180px"-->
              <!--              />-->
              <!--              <b-button-->
              <!--                v-b-tooltip.hover.top="'Filter'"-->
              <!--                variant="primary"-->
              <!--                class="d-inline-block mr-1"-->
              <!--                @click="isFilterActive = true"-->
              <!--              >-->
              <!--                <feather-icon icon="FilterIcon" />-->
              <!--              </b-button>-->

              <b-button
                v-if="!details"
                v-b-tooltip.hover.top="'Add a course'"
                class="d-inline-block text-nowrap btn-sm mr-1"
                variant="primary"
                @click="isAddActive = true"
              >
                <!-- <feather-icon icon="PlusIcon" /> -->
                Add a course
              </b-button>
              <b-button
                v-if="!details"
                v-b-tooltip.hover.top="'Add a student to one of these courses'"
                class="d-inline-block text-nowrap btn-sm mr-1"
                variant="primary"
                @click="getAddSidebare"
              >
                <!-- <feather-icon icon="UserPlusIcon" /> -->
                Add a student
              </b-button>
              <b-button
                v-if="!details"
                v-b-modal.postpone-course-modal
                v-b-tooltip.hover.top="'Postpone group'"
                class="d-inline-block text-nowrap btn-sm"
                variant="primary"
              >
                <!-- @click="postponeDate = course.date_start" -->
                <!-- <feather-icon icon="UserPlusIcon" /> -->
                Postpone group
              </b-button>
            </div>
            <b-modal
              id="postpone-course-modal"
              :ok-disabled="!postponeDate || isLoadingGroupName"
              :title="'Postpone Group '+singleCourseGroup"
              centered
              no-close-on-backdrop
              ok-only
              ok-title="Postpone"
              @ok="postponeCourse(singleCourseGroup, newGroupName)"
            >
              <b-form-group
                label="Select New Start Date"
                label-for="date-start"
              >
                <b-form-datepicker
                  v-model="postponeDate"
                  :date-disabled-fn="dateDisabled"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :start-weekday="1"
                  locale="en-UK"
                  @input="getGroupName"
                />
              </b-form-group>
              <b-form-group
                label="Current group name"
                label-for="current-group-name"
              >
                <b-form-input
                  v-model="singleCourseGroup"
                  disabled
                  placeholder="Current Group Name"
                />
              </b-form-group>
              <b-form-group
                label="New group name"
                label-for="new-group-name"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="newGroupName"
                    placeholder="New Group Name"
                  />
                  <b-spinner
                    v-if="isLoadingGroupName"
                    class="ml-1"
                    label="Text Centered"
                    variant="primary"
                  />
                </div>
              </b-form-group>
              <!-- <flat-pickr
                v-model="postponeDate"
                placeholder="DD-MM-YYYY"
                class="form-control p-2"
                :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                style="max-width: 100%"
              /> -->
            </b-modal>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refCourseListTable"
        :busy.sync="isBusy"
        :fields="details ? tableColumns.filter(el => el.key !== 'actions' && el.key !== 'selected') : tableColumns.filter(el => el.key !== 'waiting_list')"
        :items="fetchCourses"
        :selectable="!details"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :tbody-tr-class="tbodyRowClass"
        class="position-relative"
        empty-text="No matching records found"
        primary-key="id"
        responsive
        show-empty
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              v-if="!details"
              label="Text Centered"
              variant="primary"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Selected -->
        <template #cell(selected)="data">
          <b-form-checkbox
            v-if="data.item.status !== 'deleted'"
            :checked="selectedRows.some(el => el.id === data.item.id)"
            disabled
          />
          <small class="d-block text-secondary mt-25">{{ data.item.id }}</small>
          <!-- @change="onRowSelected" -->
        </template>
        <!-- Column: ID -->
        <!--        <template #cell(id)="data">-->
        <!--          <b-media vertical-align="center">-->
        <!--            &lt;!&ndash;            <b-link&ndash;&gt;-->
        <!--            &lt;!&ndash;              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"&ndash;&gt;-->
        <!--            &lt;!&ndash;              class="font-weight-bold d-block text-nowrap"&ndash;&gt;-->
        <!--            &lt;!&ndash;            >&ndash;&gt;-->
        <!--            {{ data.item.id }}-->
        <!--            &lt;!&ndash;            </b-link>&ndash;&gt;-->
        <!--          </b-media>-->
        <!--        </template>-->

        <!-- Column: Course Name  -->
        <template #cell(course)="data">

          <div class="media-body">
            <div
              class="font-weight-bold d-block text-nowrap"
            >
              <span
                v-b-tooltip.hover.top="data.item.status"
                :class="`bullet bullet-sm mr-1 ${data.item.status}-filter`"
              />
              {{ data.item.course_name }}
            </div>
            <!--            <small-->
            <!--              v-if="data.item.price"-->
            <!--              class="text-muted"-->
            <!--              style="padding-left: 2rem; white-space: nowrap;"-->
            <!--            >{{ data.item.price }} {{ $store.state.appConfig.currency }}</small>-->
          </div>
        </template>
        <!-- Column: Waiting list -->
        <template #cell(waiting_list)="data">
          <span class="">{{ data.item.waiting_list_count }}</span>
        </template>
        <!-- Column: course dates -->
        <template #cell(date_start)="data">
          <span class="">{{ data.item.formated_date_start }} | {{ data.item.formated_date_end }}</span>
          <small class="text-secondary d-block">{{ data.item.time_from }} - {{ data.item.time_to }}</small>
        </template>

        <!-- Column: teacher -->
        <template #cell(teachers_name)="data">
          <div
            v-if="data.item.pending_teacher"
            class="d-flex"
          >
            <div>
              <b-avatar-group
                v-if="data.item.pending_teacher.length !== 0 && !data.item.current_teacher"
                size="38px"
                style="min-width: 50px"
              >
                <b-avatar
                  v-for="teacher in (showAllPendingTeachers ? data.item.pending_teacher : data.item.pending_teacher.slice(0, 5))"
                  :key="teacher.id"
                  v-b-tooltip.hover="`Pending approval ${teacher.by === 'teacher' ? `of ${teacher.name} from Administration` : `from ${teacher.name}`}`"
                  :class="teacher.by === 'admin' ? 'pending-admin' : 'pending-teacher'"
                  :src="teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : teacher.avatar"
                  :text="avatarText(teacher.name)"
                  :title="teacher.name"
                  button
                  class="pull-up"
                  @click="handleChatClick1(teacher.id)"
                >
                  <!-- <template #badge><feather-icon icon="LoaderIcon"></feather-icon></template> -->
                </b-avatar>
              </b-avatar-group>
              <div
                v-if="!showAllPendingTeachers"
                class="ml-auto d-flex align-items-center"
              >
                <b
                  v-if="data.item.pending_teacher.length > 5"
                  v-b-tooltip.hover="'Show all'"
                  @click="showAllPendingTeachers = true"
                >+{{ data.item.pending_teacher.length - 5 }}</b>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="data.item.current_teacher"
                v-b-tooltip.hover="data.item.current_teacher.name"
                :src="data.item.current_teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.current_teacher.avatar"
                :text="avatarText(data.item.current_teacher.name)"
                :title="data.item.current_teacher.name"
                button
                class="pull-up active-teacher"
                size="38px"
                @click="data.item.current_teacher.id ? $router.push({ name: 'admin-teacher-calendar', params: { teacher: data.item.current_teacher } }) : modalSuggestTeacher(data.item.id)"
              >
                <!-- <template #badge><feather-icon icon="CheckIcon"></feather-icon></template> -->
              </b-avatar>
              <!-- <b-badge
              v-if="data.item.teacher_name && data.item.approved === 'yes'"
              v-b-tooltip.hover.top="data.item.teacher_name"
              pill
              variant="light-danger"
              class="text-capitalize"
            >
            >
              >
                {{ formatTeacher(data.item.teacher_name) }}
              </div>
            </div> -->
              <!-- <div
                v-b-tooltip.hover.top="data.item.teacher_name === null ? 'Pending teacher approval' : 'Pending approval from ' + data.item.teacher_name.split(' ')[1].charAt(0).toUpperCase()+'.'"
                class="avatar-content"
              >
                <feather-icon
                  icon="LoaderIcon"
                  size="16"
                  class="align-middle text-body"
                />
                {{ formatTeacher(data.item.teacher_name) }}
              </div> -->
              <span
                v-if="!data.item.current_teacher && data.item.pending_teacher.length === 0"
                class="b-avatar mr-25 badge-light-success rounded-circle"
                style="cursor: pointer"
                @click="modalSuggestTeacher(data.item.id)"
              >
                <span class="b-avatar-custom">
                  <feather-icon
                    v-b-tooltip.hover.top="'Add teacher'"
                    class="align-middle text-body"
                    icon="PlusCircleIcon"
                    size="16"
                  />
                </span>
              </span>
              <b-button
                v-if="data.item.substitutes.length !== 0"
                v-b-modal="getModal6Id(data.item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="p-0 pt-25"
                size="sm"
                variant="flat-primary"
                @click="getSubData(data.item.id)"
              >
                <b-avatar-group
                  size="22px"
                >
                  <b-avatar
                    v-for="sub in showAllSubs ? data.item.substitutes : data.item.substitutes.slice(0, 5)"
                    :key="sub.id"
                    v-b-tooltip.hover="sub.name"
                    :src="sub.avatar"
                    :title="sub.name"
                    class="pull-up m-0"
                  />
                  <div
                    v-if="!showAllSubs && data.item.substitutes.length > 5"
                    class="ml-auto d-flex align-items-center"
                  >
                    <b
                      v-b-tooltip.hover="'Show all'"
                      @click="showAllSubs = true"
                    >+{{ data.item.substitutes.length - 5 }}</b>
                  </div>
                </b-avatar-group>
              </b-button>
            </div>
          </div>
          <b-modal
            :id="'modalGroup6'+data.item.id"
            body-class="substitute-modal"
            centered
            no-close-on-backdrop
            ok-only
            ok-title="Close"
            size="xl"
          >
            <template #modal-title>
              <h5> Course : {{ data.item.course_name }} ({{ data.item.group }})
                <small v-if="data.item.current_teacher"> Current teacher: {{ data.item.current_teacher.name }}</small>
              </h5>
            </template>
            <substitute-list
              :courseId="data.item.id"
              allSessions
            />
          </b-modal>
        </template>
        <!-- Column: classroom -->
        <template #cell(classroom_name)="data">
          <span
            v-if="data.item.classroom_code"
            v-b-tooltip.hover.top="data.item.classroom_name"
            class="badge badge-pill badge-light-secondary"
          >
            {{ data.item.classroom_code }}
          </span>
          <span
            v-if="!data.item.classroom_code"
            class="b-avatar mr-1 badge-light-success rounded-circle"
            style="cursor: pointer"
            @click="modalSuggestClassroom(data.item.id)"
          >
            <span class="b-avatar-custom">
              <feather-icon
                v-b-tooltip.hover.top="'Add classroom'"
                class="align-middle text-body"
                icon="PlusCircleIcon"
                size="16"
              />
            </span>
          </span>
        </template>
        <!-- Column: Role -->
        <template #cell(student)="data">
          <div class="text-nowrap">
            <feather-icon
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              :icon="resolveUserRoleIcon(data.item.role)"
              class="mr-50"
              size="18"
            />
            <span class="align-text-top text-capitalize">{{ data.item.student_name }}</span>
          </div>
        </template>
        <!-- Column: CONF/AV -->
        <template #cell(available)="data">
          <span class="text-nowrap">
            {{ data.item.reg_conf_cnt ? data.item.reg_conf_cnt : 0 }} / {{ data.item.reg_uncnf_cnt ? data.item.reg_uncnf_cnt : 0 }} / {{ data.item.available ? data.item.available : 0 }}
          </span>
        </template>

        <!-- Column: visa -->
        <template #cell(visa)="data">
          <b-badge
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.visa_needed }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(who_pays)="data">
          <b-badge
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.who_pays }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(payer_confirmed)="data">
          <b-badge
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.payer_confirmed }}
          </b-badge>
        </template>
        <!-- Column: who pays -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.attendance }}
          </b-badge>
        </template>
        <template #cell(comment)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            class="btn-icon rounded-circle"
            size="sm"
            variant="flat-primary"
            @click="handleChatClick({model:'teacher', id: data.item.id})"
          >
            <feather-icon
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              badge-classes="bg-danger"
              class="align-middle text-body"
              icon="ClipboardIcon"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.item.status !== 'deleted'"
            :right="$store.state.appConfig.isRTL"
            no-caret
            variant="link"
          >

            <template #button-content>
              <feather-icon
                class="align-middle text-body"
                icon="MoreVerticalIcon"
                size="16"
              />
            </template>
            <b-dropdown-item
              v-b-modal="getModal8Id(data.item.id)"
            >
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">Attendance list</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.origin && data.item.status !== 'past'"
              v-b-modal="getModal9Id(data.item.id)"
              v-b-tooltip.hover.top="'Move this course\'s students to the original group'"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">Move to old group</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.current_teacher"
              @click="handleChatClick1(data.item.current_teacher.id)"
            >
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact teacher</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.has_suggestions && data.item.status !== 'past'"
              v-b-modal="getModal7Id(data.item.id)"
              @click="data.item.pending_teacher.filter(teacher => teacher.by === 'teacher').length === 1 ? approveTeacher(data.item.pending_teacher.filter(teacher => teacher.by === 'teacher')[0].teacher_id, data.item.id) : ''; reason = null; selectedTeacher = {}"
            >
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">Approve teacher</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal6Id(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Show sessions</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.classroom_code && data.item.status !== 'past'"
              @click="modalSuggestClassroom(data.item.id)"
            >
              <feather-icon icon="MousePointerIcon" />
              <span class="align-middle ml-50">Assign classroom</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!hideEdit && data.item.status !== 'past'"
              @click="editCourse(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="printAttendance(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Print attendance sheet</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="addSimilar(data.item.id)"
            >
              <feather-icon icon="CopyIcon" />
              <span
                class="align-middle ml-50"
              >Duplicate course</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'hidden' && data.item.status !== 'past'"
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="EyeOffIcon" />
              <span class="align-middle ml-50">Hide</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status === 'hidden' && data.item.status !== 'past'"
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Make visible</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="data.item.status !== 'past'"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="data.item.status === 'past'"
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="data.item.status !== 'past'"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item> -->

            <!-- <b-dropdown-item
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span
                class="align-middle ml-50"
                @click="addSimilar(data.item.id, 'add')"
              >Add Similar</span>
            </b-dropdown-item> -->
            <!-- basic modal delete-->
            <b-modal
              v-if="data.item.pending_teacher.filter(teacher => teacher.by !== 'admin').length > 1"
              :id="'modalGroup7'+data.item.id"
              centered
              no-close-on-backdrop
              ok-only
              ok-title="Approve"
              size="lg"
              title="Assign the following teacher"
              @ok="approveTeacher(selectedTeacher.teacher_id, data.item.id, reason)"
            >
              <b-form-group
                label="Teachers"
                label-for="teachers"
              >
                <v-select
                  v-model="selectedTeacher"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="data.item.pending_teacher.filter(teacher => teacher.by !== 'admin')"
                  label="name"
                >
                  <template #option="{ avatar, name }">
                    <b-avatar
                      :src="avatar"
                      size="sm"
                    />
                    <span class="ml-50"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      :src="avatar"
                      class="border border-white"
                      size="sm"
                    />
                    <span class="ml-50"> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group
                label="Reason"
                label-for="reason"
              >
                <b-form-textarea
                  v-model="reason"
                  maxlength="500"
                  placeholder="Message"
                  rows="3"
                />
              </b-form-group>
            </b-modal>
            <!-- Attendance list-->
            <b-modal
              :id="'modalGroup8'+data.item.id"
              centered
              hide-footer
              size="xl"
              title="Attendance list"
            >
              <course-attendance-list
                :course-id="data.item.id"
                @close-attendance-modal="$bvModal.hide(`modal12${data.item.id}`)"
              />
            </b-modal>
            <b-modal
              :id="'modalGroup1'+data.item.id"
              cancel-title="No"
              centered
              ok-title="Yes"
              title="Delete Course"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>Do you want to delete <strong>{{ data.item.course_name }} ({{ data.item.group }}) ?</strong></h5>
              </b-card-text>
            </b-modal>
            <!-- Move group modal -->
            <b-modal
              :id="'modalGroup9'+data.item.id"
              cancel-title="No"
              centered
              ok-title="Yes"
              title="Move group"
              @ok="moveGroup([data.item.id])"
            >
              <b-card-text>
                <h5>Do you want to move <strong>{{ data.item.course_name }} ({{ data.item.group }}) ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- Modal archive-->
            <b-modal
              :id="'modalGroup2'+data.item.id"
              cancel-title="No"
              centered
              ok-title="Yes"
              title="Archive course"
              @ok="archive(data.item.id)"
            >
              <b-card-text>
                <h5>Do you want to archive <strong>{{ data.item.course_name }} ({{ data.item.group }}) ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- Modal hide-->
            <b-modal
              :id="'modalGroup4'+data.item.id"
              cancel-title="No"
              centered
              ok-title="Yes"
              title="Hide course"
              @ok="hideCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Do you want to hide <strong>{{ data.item.course_name }} ({{ data.item.group }})</strong> from the website ?</h5>
              </b-card-text>
            </b-modal>

            <!-- Modal activate-->
            <b-modal
              :id="'modalGroup5'+data.item.id"
              cancel-title="No"
              centered
              ok-title="Yes"
              title="Show Course"
              @ok="activateCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Do you want to show <strong>{{ data.item.course_name }} ({{ data.item.group }}) ?</strong></h5>
              </b-card-text>
            </b-modal>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row v-if="!isOverView">

          <!-- Per Page -->
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="3"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50 drop-up"
            />
            <label>entries</label>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
            cols="12"
            sm="3"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
            cols="12"
            sm="6"
          >

            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalUsers"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div
        class="pb-1"
        style="background: #fff"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-icon shadow-sm ml-auto "
          size="sm"
          style="display: block; transform: translate(8px, -2px);"
          variant="light"
          @click="isChatActive = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        :model-id="chatModelId.id"
        model-type="course"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
      />
    </div>
    <div
      v-if="isChatActive1"
      class="commentschats"
    >
      <feather-icon
        class="align-middle text-body close-btn"
        icon="XSquareIcon"
        size="22"
        @click="isChatActive1 = false"
      />
      <chat-module
        :conversation="conversation"
        :messages="messages"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable,
  // BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BFormCheckbox,
  VBTooltip,
  BSpinner,
  BFormDatepicker,
  BAvatarGroup, BAvatar, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import ChatModule from '@/views/admin/ChatModule.vue'
import store from '@/store'
import CourseAttendanceList from '@/views/teacher/courses-list/CourseAttendanceList.vue'
import SubstituteList from '@/views/admin/courses-approval/SubstitutesList.vue'
import SuggestTeacher from '../courses-view/SuggestTeacher.vue'
import SuggestClassroom from '../courses-view/SeggestClassroom.vue'
import CoursesListAdd from './CoursesListAdd.vue'
// import ModalCourse from '../courses-view/ModalCourse.vue'
// import CoursesListBigModal from './CoursesListBigModal.vue'
import useCoursesGroupList from './useCoursesGroupList'
import coursesStoreModule from '../coursesStoreModule'
import coursesListGroupAdd from './CoursesListGroupAdd.vue'
// import Course from '../../Course.vue'
import CourseListSideFilter from './CourseListFilter.vue'

export default {
  name: 'CoursesListGroup',
  components: {
    CourseAttendanceList,
    SubstituteList,
    // Course,,,
    CoursesListAdd,
    CourseListSideFilter,
    coursesListGroupAdd,
    SuggestTeacher,
    SuggestClassroom,
    // ModalCourse,
    // CoursesListBigModal,
    // ModalCourse,
    ChatModule,
    BAvatarGroup,
    BAvatar,

    BFormCheckbox,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BFormTextarea,
    // BMedia,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BSpinner,
    vSelect,
    // flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: false,
    },
    singleCourseGroup: {
      type: String,
    },
    isOverView: {
      type: Boolean,
    },
    course: {
      type: Object,
    },
    details: {
      type: Boolean,
    },
  },
  data() {
    return {
      avatarText,
      allRowsSelected: false,
      isChatActive1: false,
      conversation: {},
      messages: [],
      userData: JSON.parse(localStorage.userData),
      showAllPendingTeachers: false,
      showAllSubs: false,
      newGroupName: null,
      isLoadingGroupName: false,
    }
  },
  watch: {
    isOverView(newVal) {
      this.isOverViewList = newVal
    },
  },
  mounted() {
    /* eslint-disable no-return-assign */
    if (typeof this.singleCourseGroup !== 'undefined') {
      this.courseGroup = this.singleCourseGroup
    }
  },
  methods: {
    dateDisabled(ymd, date) {
      // disable weekdays
      const weekday = date.getDay()
      return weekday === (!this.course.days.includes('mo') ? 1 : null)
          || weekday === (!this.course.days.includes('tu') ? 2 : null)
          || weekday === (!this.course.days.includes('we') ? 3 : null)
          || weekday === (!this.course.days.includes('th') ? 4 : null)
          || weekday === (!this.course.days.includes('fr') ? 5 : null)
          || weekday === (!this.course.days.includes('sa') ? 6 : null)
          || weekday === (!this.course.days.includes('su') ? 0 : null)
    },
    getGroupName() {
      const payload = {
        days: this.course.days.length > 0 ? this.course.days.join(',') : null,
        code: this.course.master_course ? this.course.master_course.code : null,
        date_start: this.postponeDate,
        time_from: this.course.time_from,
      }
      this.isLoadingGroupName = true
      this.$http.post('/course/generate/group', payload).then(res => {
        this.newGroupName = res.data
        this.isLoadingGroupName = false
      })
    },
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item && this.selectedRows.some(el => el.id === item.id)) return ['b-table-row-selected', 'cursor-pointer']
      return ['cursor-pointer']
    },
    selectAllRows() {
      this.$store.state.coursesStoreModule.courses.rows.forEach(el => {
        this.selectedRows.push(el)
      })
    },
    clearSelected() {
      // this.$refs.refCourseListTable.clearSelected()
      this.selectedRows = []
    },
    handleChatClick1(id) {
      this.$http.post('/conversation', { participants: [id], user_id: this.userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
  },
  setup(props, { emit }) {
    /* eslint-disable camelcase, no-shadow */
    const getModal1Id = id => `modalGroup1${id}`
    const getModal2Id = id => `modalGroup2${id}`
    const getModal3Id = id => `modalGroup3${id}`
    const getModal4Id = id => `modalGroup4${id}`
    const getModal5Id = id => `modalGroup5${id}`
    const getModal6Id = id => `modalGroup6${id}`
    const getModal7Id = id => `modalGroup7${id}`
    const getModal8Id = id => `modalGroup8${id}`
    const getModal9Id = id => `modalGroup9${id}`

    const USER_APP_STORE_MODULE_NAME = 'app-courses-group'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const onRowSelected = item => {
      const idToCheck = item.id
      if (selectedRows.value.some(el => el.id === idToCheck)) {
        // If already selected, remove it from selectedRows and add it to unselectedRows
        selectedRows.value = selectedRows.value.filter(el => el.id !== idToCheck)
        unselectedRows.value.push(item)
      } else if (unselectedRows.value.some(el => el.id === idToCheck)) {
        // If already unselected, remove it from unselectedRows and add it to selectedRows
        unselectedRows.value = unselectedRows.value.filter(el => el.id !== idToCheck)
        selectedRows.value.push(item)
      } else {
        // If not selected or unselected, add it to selectedRows
        selectedRows.value.push(item)
      }
    }

    const multipleRowActions = action => {
      const ids = selectedRows.value.map(el => el.id)
      if (action === 'show') activateCourse(ids)
      if (action === 'delete') remove(ids)
      if (action === 'archive') archive(ids)
      if (action === 'hide') hideCourse(ids)
    }

    const modalAction = ref('')
    const capitalizeFirstLetter = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const suggestClassroomData = ref({})
    const modalSuggestClassroom = id => {
      suggestClassroomData.value = {
        active: true,
        courseId: id,
      }
    }
    const methodOptions = ref([])
    const paidOptions = ref([])
    const isAddNewStudentSidebarActive = ref(false)
    const isFilterActive = ref(false)
    const isAddActive = ref(false)
    const isAddsimilarActive = ref(false)
    const courseAction = ref(null)
    const currentCourseData = ref(null)
    const isBigModalActive = ref(false)
    const isModalActive = ref(false)
    const singleData = ref({})
    const spinner = ref(false)
    const msgs = ref([])
    const UsersToAdd = ref([])
    const UsersAlreadyAdded = ref([])
    const coursesList = ref([])
    const subs = ref({
      data: [],
      course: {},
      current_teacher: {},
    })
    const payerOptions = ref([])
    const getSubData = course_id => {
      store.dispatch('app-courses/getSubData', course_id)
      .then(res => {
        subs.value.data = res.data.substitutes
        subs.value.course = { name: res.data.course_name, group: res.data.course_group }
        subs.value.current_teacher = res.data.current_teacher.name
      }).catch(error => console.log(error))
    }
    const getAddSidebare = function () {
      store.dispatch('coursesStoreModule/getPaymentMethod').then(res => {
        methodOptions.value = res.data.rows
      })
      store.dispatch('coursesStoreModule/getRegFilters').then(res => {
        paidOptions.value = res.data.rows
      })
      store.dispatch('registerStoreModule/fetchPayers').then(res => { payerOptions.value = res.data.rows })
      this.$http.get('/course-list/data', { params: { group_course_name: this.singleCourseGroup, perPage: 100 } })
      .then(res => coursesList.value = res.data.rows)
      isAddNewStudentSidebarActive.value = true
    }
    const printAttendance = course_id => {
      store.dispatch('coursesStoreModule/printAttendance', course_id).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    // const ModalData = (id, tab) => {
    //   console.log(tab)
    //   store.dispatch('app-courses/getSCourse', id)
    //     .then(() => {
    //       currentCourseData.value = store.state.coursesStoreModule.singleCourse
    //       const sDays = currentCourseData.value.days
    //       const daysArr = sDays.split(',')
    //       currentCourseData.value.days = daysArr
    //       if (currentCourseData.value.conversation === null) {
    //         msgs.value = []
    //         UsersToAdd.value = []
    //         UsersAlreadyAdded.value = []
    //       } else {
    //         if (typeof currentCourseData.value.conversation !== 'undefined') {
    //           msgs.value = currentCourseData.value.conversation.messages
    //         }
    //         UsersToAdd.value = currentCourseData.value.users_To_Add
    //         UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
    //       }
    //       isBigModalActive.value = true
    //     }).catch(error => console.log(error))
    // }
    const editCourse = id => {
      store.dispatch('app-courses-group/fetchTeachers', { course_id: id }).then(() => {
        teachers.value = store.state.coursesStoreModule.teachers
      })
      store.dispatch('app-courses-group/getSCourse', id)
      .then(() => {
        emit('new-course-data', store.state.coursesStoreModule.singleCourse)
        courseAction.value = 'edit'
        // return currentCourseData.value = store.state.coursesStoreModule.singleCourse
        // console.log(currentCourseData.value)
      })
      .catch(error => console.log(error))
    }
    const addSimilar = (id, action) => {
        store.dispatch('app-courses-group/getSCourse', id)
          .then(() => {
            currentCourseData.value = store.state.coursesStoreModule.singleCourse
            // console.log(currentCourseData.value)
          })
          .then(() => {
            isAddsimilarActive.value = true
            courseAction.value = action
          })
          .catch(error => console.log(error))
    }
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const query1 = ref('')
    const query2 = ref('')
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    // Use toast
    const toast = useToast()
    const {
      isBusy,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      coursesStatusOptions,
      filterDefault,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      // cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      currentUser,
      selectedRows,
      unselectedRows,
      courseGroup,
      isOverViewList,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useCoursesGroupList()

    const postponeDate = ref(null)
    /* eslint-disable */
    const postponeCourse = (group, new_group_name) => {
      store.dispatch('app-courses-group/postponeCourse', { date_start: postponeDate.value, group, new_group_name })
      .then(res => {
        emit('reset-group', new_group_name)
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const suggestTeacherData = ref({
      active: false,
      courseId: null,
    })
    const modalSuggestTeacher = id => {
      suggestTeacherData.value = {
        active: true,
        courseId: id,
      }
    }
    const reason = ref(null)
    const selectedTeacher = ref({})
    /* eslint-disable no-shadow */
    const approveTeacher = (teacher_id, course_id, reason) => {
      store.dispatch('app-courses-group/approveTeacher', { teacher_id, course_id, reason })
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const regToWaitingList = id => {
      store.dispatch('app-courses-group/regToWaitingList', id)
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }
    const formatTeacher = teacherName => {
      if (teacherName !== null) {
        const tName = teacherName.split(' ')
        let name = ''
        tName.forEach((s, i) => {
          if (i < 2) {
            name += s.charAt(0).toUpperCase()
          }
        })
        return name
      }
      return ''
    }
    const formatClassroom = teacherName => {
      if (teacherName !== null) {
        const tName = teacherName.split(' ')
        let name = ''
        tName.forEach((s, i) => {
          if (i < 2) {
            name += s.charAt(0).toUpperCase()
          }
        })
        return name
      }
      return ''
    }
    const sendPTest = pId => {
      store.dispatch('app-courses-group/sendPTest', pId)
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const hideCourse = id => {
      // const course_id = selectedCourse.value.id
      store.dispatch('app-courses-group/hideCourse', id)
      .then(response => {
        refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while hiding course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const autoCompleteCourse = search => {
      courses.value = []
      store.dispatch('app-courses-group/autoCompleteCourses', search)
      .then(response => { courses.value = response.data })
    }
    const autoCompleteTemplate = search => {
      templates.value = []
      store.dispatch('app-courses-group/autoComplete', search)
      .then(response => {
        templates.value = response.data
      })
    }

    const generateContract = id => {
      store.dispatch('app-courses-group/generateContract', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const activateCourse = id => {
      store.dispatch('app-courses-group/activateCourseAction', id).then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while activating course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const archive = confId => {
      refetchData()
      store.dispatch('app-courses-group/archiveCourse', confId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Course Has Been Archived',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while archiving course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = cancelId => {
      refetchData()
      store.dispatch('app-courses-group/cancelRegistration', cancelId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been canceled',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const remove = regId => {
      store.dispatch('app-courses-group/deleteCourse', regId)
        .then(() => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Course Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const moveGroup = course_id => {
      store.dispatch('app-courses-group/moveGroup', course_id)
        .then(res => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    /* console.log(masters) */

    return {
      payerOptions,
      selectedTeacher,
      postponeCourse,
      postponeDate,
      methodOptions,
      paidOptions,

      editCourse,
      approveTeacher,
      reason,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      getModal6Id,
      getModal7Id,
      getModal8Id,
      getModal9Id,
      // ModalData,
      addSimilar,
      singleData,
      loadSpinner,
      spinner,

      isChatActive,
      chatModelId,
      handleChatClick,

      getAddSidebare,
      coursesList,

      getSubData,
      subs,

      // Actions
      remove,
      cancel,
      archive,
      activateCourse,
      generateContract,
      hideCourse,
      sendPTest,
      regToWaitingList,
      printAttendance,
      moveGroup,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,
      autoCompleteTemplate,
      autoCompleteCourse,
      formatDateTime,
      formatTeacher,
      formatClassroom,
      isBusy,

      // Sidebar
      isFilterActive,
      // Add modal
      isAddActive,
      // infos modal
      isModalActive,
      // biggest modal remplacement
      isBigModalActive,
      isAddsimilarActive,
      courseAction,
      currentCourseData,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      filterDefault,
      coursesStatusOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      // cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      currentUser,
      selectedRows,
      unselectedRows,
      modalAction,
      capitalizeFirstLetter,
      courseGroup,
      isOverViewList,

      modalSuggestTeacher,
      suggestTeacherData,
      modalSuggestClassroom,
      suggestClassroomData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      onRowSelected,
      multipleRowActions,
      isAddNewStudentSidebarActive,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
  .courses-filter {
    min-width: 150px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
